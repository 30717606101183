/* eslint-disable react-hooks/exhaustive-deps */
import {
	HStack,
	Icon,
	Input,
	InputGroup,
	InputRightElement,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Button,
} from '@chakra-ui/react'
import { debounce } from 'lodash'
import React, { useCallback, useState } from 'react'
import { MdSearch } from 'react-icons/md'
import ActiveParticipantList from './active-participants'
import NewParticipantList from './new-participants'
import { useLanguageContext } from '../../../../context/locale.context'
// import { dataLayerTagManager } from '../../../repository/repo.utils'

const ParticipantView = ({
	activity,
	activityTypeDetails,
	isOpen,
	onClose,
	showExternal = true,
	addExternalUsers = null,
	inviteDelegate = false,
	addDelegate = null,
}) => {
	const [isReload, setisReload] = useState(false)
	const [searchQuery, setsearchQuery] = useState('')
	const [activeParticipants, setActiveParticipants] = useState([])
	const {
		state: { locale },
	} = useLanguageContext()

	const onDebounceSearch = useCallback(
		debounce(e => {
			setsearchQuery(e.target.value)
		}, 400)
	)

	return (
		<Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent borderRadius='md' bg='white' minW='70vw' m='auto' h='600px'>
				<ModalCloseButton zIndex='100' />
				<ModalBody p={0} w='100%' position='relative'>
					<HStack
						mt={2}
						position='absolute'
						top='10px'
						minW='calc(70vw - 100px)'
						// w="100%"
						mx='4rem'
						px={5}
					>
						<InputGroup mx={5} w='full'>
							<Input
								bg='white'
								placeholder={locale['Smart search']}
								borderRadius='md'
								variant='filled'
								boxShadow='md'
								onChange={e => {
									// dataLayerTagManager('button_click', {
									//   viewName: 'Add Participant',
									//   buttonName: 'Smart Search',
									// })
									onDebounceSearch(e)
									setsearchQuery(e.target.value)
								}}
								_focus={{
									border: 'none',
								}}
								value={searchQuery}
							/>
							<InputRightElement
								children={
									<Icon
										as={MdSearch}
										color={localStorage.getItem('color')}
										w={6}
										h={6}
									/>
								}
							/>
						</InputGroup>
					</HStack>
					<HStack h='full' mt='50px' alignItems='flex-start'>
						<ActiveParticipantList
							isReload={isReload}
							onSetReload={e => {
								setisReload(e)
								setsearchQuery('')
							}}
							activity={activity}
							activityTypeDetails={activityTypeDetails}
							search_string={searchQuery}
							setActiveParticipants={setActiveParticipants}
						/>
						<NewParticipantList
							activity={activity}
							search_string={searchQuery}
							activeParticipants={activeParticipants}
							isReload={isReload}
							onSetReload={e => {
								setisReload(e)
								setsearchQuery('')
							}}
							inviteDelegate={inviteDelegate}
							addDelegate={addDelegate}
						/>
					</HStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}

export default ParticipantView
