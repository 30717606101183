/* eslint-disable react-hooks/exhaustive-deps */
import {
	Avatar,
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	List,
	ListItem,
	Text,
	VStack,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	HStack,
	FormControl,
	FormLabel,
	Input,
	Checkbox,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
// import {
// 	dataLayerTagManager,
// 	eventLabelTrack,
// 	GA_CATEGORY_DIALOG_BOX,
// 	sectionDetailedTrack,
// } from './../../../../repository/repo.utils'
import {
	useInviteParticipant,
	useSearchOrgContacts,
	useExternalGuestsAdd,
} from '../../../../../hooks/activity.hooks'
import { useLanguageContext } from '../../../../../context/locale.context'

const NewParticipantList = ({
	activity,
	isReload,
	activeParticipants,
	search_string,
	onSetReload,
	inviteDelegate = false,
	addDelegate = null,
}) => {
	const [data, setdata] = useState([])
	const { activity_id, activity_type_id, activity_type_category_id } = activity
	const { mutate: inviteMutate } = useInviteParticipant()
	const { mutate: searchContactMutate } = useSearchOrgContacts()
	const { mutate: externalGuestsAddMutate } = useExternalGuestsAdd()

	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [designation, setDesignation] = useState('')
	const [send_email, setSendMail] = useState(0)
	const [error, setError] = useState('')
	const handleInput = n => e => {
		if (n === 'name') {
			setName(e.target.value)
		} else if (n === 'designation') {
			setDesignation(e.target.value)
		} else if (n === 'email') {
			setEmail(e.target.value)
		} else if (n === 'sendmail') {
			setSendMail(!!e.target.checked ? 1 : 0)
		}
		setError('')
	}

	const {
		state: { locale },
	} = useLanguageContext()

	const addEmail = () => {
		const createRegExp = (str, opts) =>
			new RegExp(str.raw[0].replace(/\s/gm, ''), opts || '')
		const email_regex = createRegExp`
      ^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|
      (\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|
      (([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$`
		if (!name || !designation || !email) {
			setError('Enter all fields')
		} else {
			if (email.match(email_regex)) {
				addExternalGuests({
					emails: [{ email, designation, name }],
					workflow_activity_id: activity.activity_id,
					activity_type_id: activity.activity_type_id,
					send_email: send_email,
				})
			} else {
				setError('Enter valid email')
			}
		}
	}

	const addExternalGuests = useCallback(params => {
		externalGuestsAddMutate(
			{ ...params },
			{
				onSuccess: async data => {
					setEmail('')
					setName('')
					setDesignation('')
					setError('')
					onSetReload(true)
				},
				onError: async data => {
					onSetReload(true)
				},
			}
		)
	})

	const inviteUser = useCallback(
		({
			contact_asset_type_id,
			contact_asset_id,
			contact_first_name,
			contact_operating_asset_name,
			workforce_name,
		}) => {
			inviteMutate(
				{
					activity_id,
					activity_type_category_id: activity_type_category_id,
					activity_type_id,
					contact: {
						asset_type_id: contact_asset_type_id,
						asset_id: contact_asset_id,
						asset_first_name: contact_first_name,
						operating_asset_first_name: contact_operating_asset_name,
						workforce_name,
					},
				},
				{
					onSuccess: async data => {
						onSetReload(true)
					},
				}
			)
		}
	)

	const loadContacts = useCallback(search_string => {
		searchContactMutate(
			{ search_string },
			{
				onSuccess: async data => {
					setdata(data)
				},
			}
		)
	})

	useEffect(() => {
		loadContacts('s')
	}, [])

	useEffect(() => {
		if (!!search_string) {
			loadContacts(search_string)
		}
		if (!!isReload) {
			loadContacts(search_string)
		}
	}, [search_string, isReload])

	return (
		<VStack
			h='calc(100% - 50px)'
			borderRightRadius='md'
			flex={1}
			display='flex'
		>
			<Box bg='white' w='100%' my={4} py={2} mt='15px' textAlign='center'>
				<Tabs isLazy>
					<TabList>
						<Tab>{locale['Internal']}</Tab>
						<Tab>{locale['External']}</Tab>
					</TabList>
					<TabPanels>
						<TabPanel bg='white'>
							<List px={5} w='full' spacing={3} h='440px' overflowY='scroll'>
								{data &&
									(data || []).map(u => {
										if (activeParticipants.includes(u.contact_asset_id)) {
											return null
										}
										const {
											contact_operating_asset_name: fname,
											contact_phone_country_code: cCode,
											contact_phone_number: pCode,
											account_name,
											workforce_name,
											asset_type_name = '',
										} = u
										return (
											<React.Fragment key={u.contact_asset_id}>
												<ListItem>
													<Flex spacing={2}>
														<Center position='relative' alignSelf='center'>
															<Avatar
																name={fname}
																bg='white'
																color='black'
																boxShadow='md'
																size='md'
															/>
														</Center>
														<Box ml={2} flex={1} textAlign='left'>
															<Text
																color='brand.800'
																fontSize='14px'
																textTransform='capitalize'
															>
																{fname}
															</Text>
															<Text
																color='brand.800'
																fontSize='14px'
																textTransform='capitalize'
															>
																{asset_type_name}
															</Text>
															<Text color='brand.800' fontSize='12px'>
																{`+${cCode} ${pCode}`} | {workforce_name} |{' '}
																{account_name}
															</Text>
														</Box>
														<Button
															size='sm'
															bg='white'
															boxShadow='md'
															borderRadius='md'
															mx={1}
															color='brand.900'
															onClick={() => {
																// eventLabelTrack({
																// 	categoryId: 0,
																// 	actionId: 8,
																// 	label: `Inviting User To Activity`,
																// })
																// dataLayerTagManager('button_click', {
																// 	viewName: 'Add Participant',
																// 	buttonName: 'Internal Invite',
																// })
																if (inviteDelegate) {
																	addDelegate(u)
																} else {
																	inviteUser(u)
																}
															}}
														>
															{inviteDelegate ? ['Delegate'] : locale['Invite']}
														</Button>
													</Flex>
												</ListItem>
												<Divider border='1px' borderColor='white' />
											</React.Fragment>
										)
									})}
							</List>
						</TabPanel>
						<TabPanel bg='white'>
							<VStack w='full'>
								<FormControl boxShadow='md' borderRadius='md' p={2} mb={2}>
									<HStack
										alignItems='center'
										justifyContent='space-between'
										mb={2}
									>
										<VStack>
											<FormLabel fontWeight='400' fontSize='md' my={1}>
												{'Name'}
											</FormLabel>
										</VStack>
									</HStack>
									<Input
										value={name}
										autoFocus={true}
										placeholder='Name'
										required
										onChange={handleInput('name')}
									/>
								</FormControl>
								<FormControl boxShadow='md' borderRadius='md' p={2} mb={2}>
									<HStack
										alignItems='center'
										justifyContent='space-between'
										mb={2}
									>
										<VStack>
											<FormLabel fontWeight='400' fontSize='md' my={1}>
												{'Designation'}
											</FormLabel>
										</VStack>
									</HStack>
									<Input
										value={designation}
										autoFocus={true}
										placeholder='Designation'
										required
										onChange={handleInput('designation')}
									/>
								</FormControl>
								<FormControl boxShadow='md' borderRadius='md' p={2} mb={2}>
									<HStack
										alignItems='center'
										justifyContent='space-between'
										mb={2}
									>
										<VStack>
											<FormLabel fontWeight='400' fontSize='md' my={1}>
												{'Email'}
											</FormLabel>
										</VStack>
									</HStack>
									<Input
										value={email}
										autoFocus={true}
										placeholder='Email'
										required
										onChange={handleInput('email')}
									/>
								</FormControl>
								<FormControl boxShadow='md' borderRadius='md' p={2} mb={2}>
									<HStack
										alignItems='left'
										justifyContent='space-between'
										mb={2}
									>
										<Checkbox
											color={localStorage.getItem('color')}
											onChange={handleInput('sendmail')}
										>
											{locale['Send invite mail']}
										</Checkbox>
									</HStack>
								</FormControl>
							</VStack>
							<Text my={2} color='red'>
								{error}
							</Text>
							<HStack w='full' justifyContent='flex-end' my={1} p={2}>
								<Button
									variant='solid'
									size='sm'
									borderRadius='md'
									colorScheme={localStorage.getItem('color')}
									bg={localStorage.getItem('color')}
									w='100px'
									ml={1}
									onClick={() => {
										// sectionDetailedTrack({
										// 	category: GA_CATEGORY_DIALOG_BOX,
										// 	action: 'Active Participant',
										// 	label: 'Invite',
										// })
										// dataLayerTagManager('button_click', {
										// 	viewName: 'Add Participant',
										// 	buttonName: 'External Invite',
										// })
										addEmail()
									}}
								>
									{inviteDelegate ? ['Delegate'] : locale['Invite']}
								</Button>
							</HStack>
						</TabPanel>
					</TabPanels>
				</Tabs>
			</Box>
		</VStack>
	)
}

export default NewParticipantList
