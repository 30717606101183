/* eslint-disable react-hooks/exhaustive-deps */
import {
	Avatar,
	Box,
	Button,
	Center,
	Divider,
	Flex,
	Heading,
	Icon,
	IconButton,
	List,
	ListItem,
	Text,
	Tooltip,
	VStack,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useAuthContext } from '../../../../../context/auth.context'
import { useLanguageContext } from '../../../../../context/locale.context'
import {
	useLoadActivityParticipant,
	useRemoveParticipant,
	useSetParticipantAsOwner,
	useSetParticipantAsLead,
} from '../../../../../hooks/activity.hooks'
import { sharedConst } from '../../../../../utils/action.constant'
import { getMessageUniqueId } from '../../../../../utils/common.util'
import { formatDueDateForTimelineCurrDate } from '../../../../../utils/date.utils'
// import {
// 	dataLayerTagManager,
// 	sectionDetailedTrack,
// } from '../../../../repository/repo.utils'
import { useSharedContext } from './../../../../../context/shared.context'
import { useLoadActivityDetails } from './../../../../../hooks/activity.hooks'
import { GA_CATEGORY_DIALOG_BOX } from './../../../../repository/repo.utils'

const ActiveParticipantList = ({
	activity,
	activityTypeDetails,
	search_string = '',
	isReload,
	onSetReload,
	setActiveParticipants,
}) => {
	const {
		state: { authData },
	} = useAuthContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const { dispatch } = useSharedContext()

	const [data, setdata] = useState([])
	const [participantPayload, setParticipantPayload] = useState({})
	const [isActivityCreator] = useState(() => {
		return activity.activity_creator_asset_id === authData.asset_id
	})

	const { mutate } = useLoadActivityParticipant()
	const { mutate: setOwnerMutate } = useSetParticipantAsOwner()
	const { mutate: setLeadMutate } = useSetParticipantAsLead()
	const { mutate: removeMutate } = useRemoveParticipant()
	const { mutate: mutateLoadActivity } = useLoadActivityDetails()

	const {
		activity_id,
		activity_master_data,
		activity_creator_asset_id: creator,
		activity_lead_asset_id: lead,
	} = activity

	const { form_fill_request: requiredList } = activity_master_data
		? JSON.parse(activity_master_data)
		: {}
	const {
		operating_asset_first_name: setterName,
		asset_id: loggedInuserId,
		asset_flag_super_admin,
	} = authData

	// CHECK WETHER USER IS HAVING REMOVE ACCESS OR NOT
	const ownerList = data
		? (data.list || [])
				.map(u => !!u.asset_flag_is_owner && u.asset_id)
				.filter(Boolean)
		: []
	let canHaveRemoveAccess =
		asset_flag_super_admin === 1
			? true
			: [creator, lead, ...ownerList].includes(loggedInuserId)

	const loadDetails = useCallback(activity => {
		mutateLoadActivity(
			{
				activity_id: activity.activity_id,
				activity_type_id: activity.activity_type_id,
			},
			{
				onSuccess: async details => {
					const isLead = loggedInuserId === details.activity_lead_asset_id
					const isOwner = asset_flag_super_admin
					dispatch({
						type: sharedConst.ACTVITY_SELECTED,
						payload: {
							...details,
							isLead,
							isOwner,
						},
					})
				},
				onError: async err => {
					console.log('Err occurred', err)
				},
			}
		)
	})

	const removeParticipant = useCallback(participant => {
		removeMutate(
			{
				activity_id,
				activity_participant_collection: JSON.stringify([
					{
						...participant,
						access_role_id: 0,
						message_unique_id: getMessageUniqueId(),
					},
				]),
			},
			{
				onSuccess: async data => {
					onSetReload(true)
				},
				onError: async error => {
					console.log('remove error', error)
				},
			}
		)
	})

	const setOwner = useCallback(
		({ asset_id, operating_asset_first_name: pName }, owner = true) => {
			let content = owner
				? `${setterName} set ${pName} as owner @ ${formatDueDateForTimelineCurrDate()}`
				: `${setterName} revoke ownership ${pName} @ ${formatDueDateForTimelineCurrDate()}`
			setOwnerMutate(
				{
					owner_flag: owner ? 1 : 0,
					target_asset_id: asset_id,
					activity_id,
					content,
				},
				{
					onSuccess: async data => {
						onSetReload(true)
					},
					onError: async error => {
						console.log('remove error', error)
					},
				}
			)
		}
	)

	const setLead = useCallback(({ asset_id }) => {
		setLeadMutate(
			{ lead_asset_id: asset_id, activity_id },
			{
				onSuccess: async data => {
					onSetReload(true)
					loadDetails(activity)
					setTimeout(() => {
						dispatch({
							type: sharedConst.REFRESH_LISTING,
						})
					}, 1000)
				},
				onError: async error => {
					console.log('error on set lead', error)
				},
			}
		)
	})

	const loadParticipants = useCallback(() => {
		mutate(
			{ activity_id },
			{
				onSuccess: async data => {
					console.log(data)
					setdata(data)
					let activeOwners = data
						? (data.list || []).filter(u => !!u.asset_flag_is_owner)
						: []
					let activeExternals = data
						? (data.list || []).filter(u => u.asset_type_category_id === 45)
						: []
					let activeInternals = data
						? (data.list || []).filter(u => u.asset_type_category_id) //Should fetch all participants
						: []
					let viewers = data
						? (data.list || []).filter(
								u => u.asset_participant_access_id === 152
						  )
						: []
					setParticipantPayload({
						activeOwners,
						activeExternals,
						activeInternals,
						viewers,
					})
					console.log(['activeInternals', activeInternals])
					console.log(['activeOwners', activeOwners])
					console.log(['activeExternals', activeExternals])
					let activepId = data ? (data.list || []).map(u => u.asset_id) : []
					setActiveParticipants(activepId)
					onSetReload(false)
				},
			}
		)
	})

	const canHaveOwnerAccess = () => {
		let isOwner = data.list.find(
			i => i.asset_flag_is_owner === 1 && i.asset_id === loggedInuserId
		)

		if (!!isOwner) {
			return true
		} else {
			return false
		}
	}

	useEffect(() => {
		loadParticipants()
	}, [])

	useEffect(() => {
		if (!!isReload) {
			loadParticipants()
		}
	}, [isReload])

	return (
		<VStack h='full' w='55%'>
			<Box
				bg='white'
				w='100%'
				my={4}
				py={2}
				textAlign='center'
				borderRight='solid 1px #70707047'
			>
				<Tabs isLazy>
					<TabList>
						{participantPayload &&
						participantPayload.activeInternals &&
						participantPayload.activeInternals.length > 0 ? (
							<Tab>{locale['All']}</Tab>
						) : null}
						{participantPayload &&
						participantPayload.activeOwners &&
						participantPayload.activeOwners.length > 1 ? (
							<Tab>{locale['Owners']}</Tab>
						) : null}
						{participantPayload &&
						participantPayload.activeExternals &&
						participantPayload.activeExternals.length > 0 ? (
							<Tab>{locale['External']}</Tab>
						) : null}
						{participantPayload &&
						participantPayload.viewers &&
						participantPayload.viewers.length > 0 ? (
							<Tab>{locale['Viewers']}</Tab>
						) : null}
					</TabList>
					<TabPanels>
						{participantPayload &&
						participantPayload.activeInternals &&
						participantPayload.activeInternals.length > 0 ? (
							<TabPanel bg='white'>
								<List px={5} w='full' spacing={3} h='440px' overflowY='scroll'>
									{(participantPayload.activeInternals || [])
										.filter(u => {
											if (!!search_string) {
												return u.operating_asset_first_name
													.toLowerCase()
													.includes(search_string.toLowerCase())
											}
											return u
										})
										.map(u => {
											const {
												account_name,
												asset_flag_is_owner: isOwner,
												operating_asset_first_name: fName,
												operating_asset_phone_number: pCode,
												operating_asset_phone_country_code: cCode,
												workforce_name,
												contact_email_id,
												activity_creator_asset_id,
												asset_id,
												asset_type_name,
											} = u
											let isCreator = activity_creator_asset_id === asset_id
											let arpLeadSettingEnabled =
												activityTypeDetails?.activity_type_arp_lead_setting_enabled ===
												1
											let islead = lead === asset_id
											let isDelegated =
												asset_flag_super_admin === 1
													? false
													: (requiredList || []).find(
															d => d.asset_id === asset_id
													  )
											return (
												<>
													<ListItem>
														<Flex spacing={2} alignItems='center'>
															<Center position='relative' alignSelf='center'>
																<Avatar
																	name={fName}
																	color={!!islead ? 'white' : 'black.400'}
																	bg={!!islead ? 'brand.800' : 'secondary'}
																	size='md'
																/>
															</Center>
															<VStack ml={2} flex={1} alignItems='flex-start'>
																<Text
																	color='brand.800'
																	fontSize='14px'
																	textTransform='capitalize'
																>
																	{fName}
																	{!!isCreator && (
																		<Text
																			as='span'
																			color='brand.800'
																			fontSize='14px'
																			fontWeight='600'
																			ml={2}
																		>
																			({locale['Creator']})
																		</Text>
																	)}
																	{lead === asset_id && (
																		<Text
																			as='span'
																			color='brand.800'
																			fontSize='14px'
																			fontWeight='600'
																			ml={2}
																		>
																			({locale['Lead']})
																		</Text>
																	)}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{asset_type_name}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{`+${cCode} ${pCode}`} | {contact_email_id}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{workforce_name} | {account_name}
																</Text>
															</VStack>
															{!isOwner ? (
																<Button
																	isDisabled={!canHaveOwnerAccess()}
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	onClick={() => {
																		// sectionDetailedTrack({
																		// 	category: GA_CATEGORY_DIALOG_BOX,
																		// 	action: 'Active Participant',
																		// 	label: 'Set Owner',
																		// })
																		// dataLayerTagManager('button_click', {
																		// 	viewName: 'Add Participant',
																		// 	buttonName: 'Set Owner',
																		// })
																		setOwner(u)
																	}}
																>
																	{locale['Set Owner']}
																</Button>
															) : (
																<Button
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	rightIcon={
																		!isCreator ? (
																			// enable this only for owner
																			<Icon
																				isDisabled={!canHaveOwnerAccess()}
																				as={MdClose}
																				w={6}
																				h={6}
																				onClick={() => {
																					// sectionDetailedTrack({
																					// 	category: GA_CATEGORY_DIALOG_BOX,
																					// 	action: 'Active Participant',
																					// 	label: 'Owner',
																					// })
																					// dataLayerTagManager('button_click', {
																					// 	viewName: 'Add Participant',
																					// 	buttonName: 'Remove Owner',
																					// })
																					setOwner(u, false)
																				}}
																			/>
																		) : null
																	}
																>
																	{locale['Owner']}
																</Button>
															)}
															{arpLeadSettingEnabled && lead !== asset_id && (
																<Button
																	isDisabled={!isActivityCreator}
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	onClick={() => {
																		// sectionDetailedTrack({
																		// 	category: GA_CATEGORY_DIALOG_BOX,
																		// 	action: 'Active Participant',
																		// 	label: 'Set Lead',
																		// })
																		// dataLayerTagManager('button_click', {
																		// 	viewName: 'Add Participant',
																		// 	buttonName: 'Set Lead',
																		// })
																		setLead(u)
																	}}
																>
																	{locale['Set Lead']}
																</Button>
															)}
															{!isCreator && !isOwner && (
																<Tooltip
																	aria-label='cross-tooltip'
																	label={
																		!!isDelegated
																			? 'Delegated user'
																			: 'Remove participant'
																	}
																>
																	<IconButton
																		isDisabled={
																			!!isDelegated || !canHaveRemoveAccess
																		}
																		bg='white'
																		borderRadius='md'
																		boxShadow='md'
																		aria-label='More'
																		size='sm'
																		_focus={{
																			bg: 'secondary',
																		}}
																		_hover={{
																			bg: 'secondary',
																		}}
																		icon={<Icon as={MdClose} w={6} h={6} />}
																		onClick={() => {
																			// sectionDetailedTrack({
																			// 	category: GA_CATEGORY_DIALOG_BOX,
																			// 	action: 'Active Participant',
																			// 	label: 'Remove Participant',
																			// })
																			// dataLayerTagManager('button_click', {
																			// 	viewName: 'Add Participant',
																			// 	buttonName: 'Remove Participant',
																			// })
																			removeParticipant(u)
																		}}
																	/>
																</Tooltip>
															)}
														</Flex>
													</ListItem>
													<Divider border='1px' borderColor='secondary' />
												</>
											)
										})}
								</List>
							</TabPanel>
						) : null}

						{participantPayload &&
						participantPayload.activeOwners &&
						participantPayload.activeOwners.length > 1 ? (
							<TabPanel bg='white'>
								<List px={5} w='full' spacing={3} h='440px' overflowY='scroll'>
									{(participantPayload.activeOwners || [])
										.filter(u => {
											if (!!search_string) {
												return u.operating_asset_first_name
													.toLowerCase()
													.includes(search_string.toLowerCase())
											}
											return u
										})
										.map(u => {
											const {
												account_name,
												asset_flag_is_owner: isOwner,
												operating_asset_first_name: fName,
												operating_asset_phone_number: pCode,
												operating_asset_phone_country_code: cCode,
												workforce_name,
												activity_creator_asset_id,
												contact_email_id,
												asset_id,
												asset_type_name,
											} = u
											let isCreator = activity_creator_asset_id === asset_id
											let islead = lead === asset_id
											let isDelegated = (requiredList || []).find(
												d => d.asset_id === asset_id
											)
											return (
												<>
													<ListItem>
														<Flex spacing={2} alignItems='center'>
															<Center position='relative' alignSelf='center'>
																<Avatar
																	name={fName}
																	color={!!islead ? 'white' : 'black.400'}
																	bg={!!islead ? 'brand.800' : 'secondary'}
																	size='md'
																/>
															</Center>
															<VStack ml={2} flex={1} alignItems='flex-start'>
																<Text
																	color='brand.800'
																	fontSize='14px'
																	textTransform='capitalize'
																>
																	{fName}
																	{!!isCreator && (
																		<Text
																			as='span'
																			color='brand.800'
																			fontSize='14px'
																			fontWeight='600'
																			ml={2}
																		>
																			({locale['Creator']})
																		</Text>
																	)}
																	{lead === asset_id && (
																		<Text
																			as='span'
																			color='brand.800'
																			fontSize='14px'
																			fontWeight='600'
																			ml={2}
																		>
																			({locale['Lead']})
																		</Text>
																	)}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{asset_type_name}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{`+${cCode} ${pCode}`} | {contact_email_id}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{workforce_name} | {account_name}
																</Text>
															</VStack>
															{!isOwner && canHaveOwnerAccess() ? (
																<Button
																	isDisabled={!canHaveOwnerAccess()}
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	onClick={() => {
																		// sectionDetailedTrack({
																		// 	category: GA_CATEGORY_DIALOG_BOX,
																		// 	action: 'Active Participant',
																		// 	label: 'Set Owner',
																		// })
																		// dataLayerTagManager('button_click', {
																		// 	viewName: 'Add Participant',
																		// 	buttonName: 'Set Owner',
																		// })
																		setOwner(u)
																	}}
																>
																	{locale['Set Owner']}
																</Button>
															) : null}
															{!!isOwner ? (
																<Button
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	rightIcon={
																		!isCreator ? (
																			// enable this only for owner
																			<Icon
																				isDisabled={!canHaveOwnerAccess()}
																				as={MdClose}
																				w={6}
																				h={6}
																				onClick={() => {
																					// sectionDetailedTrack({
																					// 	category: GA_CATEGORY_DIALOG_BOX,
																					// 	action: 'Active Participant',
																					// 	label: 'Owner',
																					// })
																					// dataLayerTagManager('button_click', {
																					// 	viewName: 'Add Participant',
																					// 	buttonName: 'Remove Owner',
																					// })
																					setOwner(u, false)
																				}}
																			/>
																		) : null
																	}
																>
																	{locale['Owner']}
																</Button>
															) : null}
															{!isCreator && !isOwner && (
																<Tooltip
																	aria-label='cross-tooltip'
																	label={
																		!!isDelegated
																			? 'Delegated user'
																			: 'Remove participant'
																	}
																>
																	<IconButton
																		isDisabled={
																			!!isDelegated || !canHaveRemoveAccess
																		}
																		bg='white'
																		borderRadius='md'
																		boxShadow='md'
																		aria-label='More'
																		size='sm'
																		_focus={{
																			bg: 'secondary',
																		}}
																		_hover={{
																			bg: 'secondary',
																		}}
																		icon={<Icon as={MdClose} w={6} h={6} />}
																		onClick={() => {
																			// sectionDetailedTrack({
																			// 	category: GA_CATEGORY_DIALOG_BOX,
																			// 	action: 'Active Participant',
																			// 	label: 'Remove Participant',
																			// })
																			// dataLayerTagManager('button_click', {
																			// 	viewName: 'Add Participant',
																			// 	buttonName: 'Remove Participant',
																			// })
																			removeParticipant(u)
																		}}
																	/>
																</Tooltip>
															)}
														</Flex>
													</ListItem>
													<Divider border='1px' borderColor='secondary' />
												</>
											)
										})}
								</List>
							</TabPanel>
						) : null}
						{participantPayload &&
						participantPayload.activeExternals &&
						participantPayload.activeExternals.length > 0 ? (
							<TabPanel bg='white'>
								<List px={5} w='full' spacing={3} h='440px' overflowY='scroll'>
									{(participantPayload.activeExternals || [])
										.filter(u => {
											if (!!search_string) {
												return u.operating_asset_first_name
													.toLowerCase()
													.includes(search_string.toLowerCase())
											}
											return u
										})
										.map(u => {
											const {
												account_name,
												asset_flag_is_owner: isOwner,
												operating_asset_first_name: fName,
												operating_asset_phone_number: pCode,
												operating_asset_phone_country_code: cCode,
												workforce_name,
												activity_creator_asset_id,
												asset_id,
												contact_email_id,
												asset_type_name,
											} = u
											let isCreator = activity_creator_asset_id === asset_id
											let islead = lead === asset_id
											let isDelegated = (requiredList || []).find(
												d => d.asset_id === asset_id
											)
											return (
												<>
													<ListItem>
														<Flex spacing={2} alignItems='center'>
															<Center position='relative' alignSelf='center'>
																<Avatar
																	name={fName}
																	color={!!islead ? 'white' : 'black.400'}
																	bg={!!islead ? 'brand.800' : 'secondary'}
																	size='md'
																/>
															</Center>
															<VStack ml={2} flex={1} alignItems='flex-start'>
																<Text
																	color='brand.800'
																	fontSize='14px'
																	textTransform='capitalize'
																>
																	{fName}
																	{!!isCreator && (
																		<Text
																			as='span'
																			color='brand.800'
																			fontSize='14px'
																			fontWeight='600'
																			ml={2}
																		>
																			({locale['Creator']})
																		</Text>
																	)}
																	{lead === asset_id && (
																		<Text
																			as='span'
																			color='brand.800'
																			fontSize='14px'
																			fontWeight='600'
																			ml={2}
																		>
																			({locale['Lead']})
																		</Text>
																	)}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{asset_type_name}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{`+${cCode} ${pCode}`} | {contact_email_id}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{workforce_name} | {account_name}
																</Text>
															</VStack>
															{!isOwner ? (
																<Button
																	isDisabled={!canHaveOwnerAccess()}
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	onClick={() => {
																		// sectionDetailedTrack({
																		// 	category: GA_CATEGORY_DIALOG_BOX,
																		// 	action: 'Active Participant',
																		// 	label: 'Set Owner',
																		// })
																		// dataLayerTagManager('button_click', {
																		// 	viewName: 'Add Participant',
																		// 	buttonName: 'Set Owner',
																		// })
																		setOwner(u)
																	}}
																>
																	{locale['Set Owner']}
																</Button>
															) : (
																<Button
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	rightIcon={
																		!isCreator ? (
																			// enable this only for owner
																			<Icon
																				isDisabled={!canHaveOwnerAccess()}
																				as={MdClose}
																				w={6}
																				h={6}
																				onClick={() => {
																					// sectionDetailedTrack({
																					// 	category: GA_CATEGORY_DIALOG_BOX,
																					// 	action: 'Active Participant',
																					// 	label: 'Owner',
																					// })
																					// dataLayerTagManager('button_click', {
																					// 	viewName: 'Add Participant',
																					// 	buttonName: 'Remove Owner',
																					// })
																					setOwner(u, false)
																				}}
																			/>
																		) : null
																	}
																>
																	{locale['Owner']}
																</Button>
															)}
															{!isCreator && !isOwner && (
																<Tooltip
																	aria-label='cross-tooltip'
																	label={
																		!!isDelegated
																			? 'Delegated user'
																			: 'Remove participant'
																	}
																>
																	<IconButton
																		isDisabled={
																			!!isDelegated || !canHaveRemoveAccess
																		}
																		bg='white'
																		borderRadius='md'
																		boxShadow='md'
																		aria-label='More'
																		size='sm'
																		_focus={{
																			bg: 'secondary',
																		}}
																		_hover={{
																			bg: 'secondary',
																		}}
																		icon={<Icon as={MdClose} w={6} h={6} />}
																		onClick={() => {
																			// sectionDetailedTrack({
																			// 	category: GA_CATEGORY_DIALOG_BOX,
																			// 	action: 'Active Participant',
																			// 	label: 'Remove Participant',
																			// })
																			// dataLayerTagManager('button_click', {
																			// 	viewName: 'Add Participant',
																			// 	buttonName: 'Remove Participant',
																			// })
																			removeParticipant(u)
																		}}
																	/>
																</Tooltip>
															)}
														</Flex>
													</ListItem>
													<Divider border='1px' borderColor='secondary' />
												</>
											)
										})}
								</List>
							</TabPanel>
						) : null}

						{participantPayload &&
						participantPayload.viewers &&
						participantPayload.viewers.length > 0 ? (
							<TabPanel bg='white'>
								<List px={5} w='full' spacing={3} h='440px' overflowY='scroll'>
									{(participantPayload.viewers || [])
										.filter(u => {
											if (!!search_string) {
												return u.operating_asset_first_name
													.toLowerCase()
													.includes(search_string.toLowerCase())
											}
											return u
										})
										.map(u => {
											const {
												account_name,
												asset_flag_is_owner: isOwner,
												operating_asset_first_name: fName,
												operating_asset_phone_number: pCode,
												operating_asset_phone_country_code: cCode,
												workforce_name,
												activity_creator_asset_id,
												contact_email_id,
												asset_id,
												asset_type_name,
											} = u
											let isCreator = activity_creator_asset_id === asset_id
											let islead = lead === asset_id
											let isDelegated = (requiredList || []).find(
												d => d.asset_id === asset_id
											)
											return (
												<>
													<ListItem>
														<Flex spacing={2} alignItems='center'>
															<Center position='relative' alignSelf='center'>
																<Avatar
																	name={fName}
																	color={!!islead ? 'white' : 'black.400'}
																	bg={!!islead ? 'brand.800' : 'secondary'}
																	size='md'
																/>
															</Center>
															<VStack ml={2} flex={1} alignItems='flex-start'>
																<Text
																	color='brand.800'
																	fontSize='14px'
																	textTransform='capitalize'
																>
																	{fName}
																	{!!isCreator && (
																		<Text
																			as='span'
																			color='brand.800'
																			fontSize='14px'
																			fontWeight='600'
																			ml={2}
																		>
																			({locale['Creator']})
																		</Text>
																	)}
																	{lead === asset_id && (
																		<Text
																			as='span'
																			color='brand.800'
																			fontSize='14px'
																			fontWeight='600'
																			ml={2}
																		>
																			({locale['Lead']})
																		</Text>
																	)}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{asset_type_name}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{`+${cCode} ${pCode}`} | {contact_email_id}
																</Text>
																<Text
																	color='brand.800'
																	fontSize='12px'
																	textAlign='left'
																>
																	{workforce_name} | {account_name}
																</Text>
															</VStack>
															{!isOwner && canHaveOwnerAccess() ? (
																<Button
																	isDisabled={!canHaveOwnerAccess()}
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	onClick={() => {
																		// sectionDetailedTrack({
																		// 	category: GA_CATEGORY_DIALOG_BOX,
																		// 	action: 'Active Participant',
																		// 	label: 'Set Owner',
																		// })
																		// dataLayerTagManager('button_click', {
																		// 	viewName: 'Add Participant',
																		// 	buttonName: 'Set Owner',
																		// })
																		setOwner(u)
																	}}
																>
																	{locale['Set Owner']}
																</Button>
															) : null}
															{!!isOwner ? (
																<Button
																	size='sm'
																	bg='white'
																	boxShadow='md'
																	borderRadius='md'
																	mr={2}
																	color='brand.900'
																	rightIcon={
																		!isCreator ? (
																			// enable this only for owner
																			<Icon
																				isDisabled={!canHaveOwnerAccess()}
																				as={MdClose}
																				w={6}
																				h={6}
																				onClick={() => {
																					// sectionDetailedTrack({
																					// 	category: GA_CATEGORY_DIALOG_BOX,
																					// 	action: 'Active Participant',
																					// 	label: 'Owner',
																					// })
																					// dataLayerTagManager('button_click', {
																					// 	viewName: 'Add Participant',
																					// 	buttonName: 'Remove Owner',
																					// })
																					setOwner(u, false)
																				}}
																			/>
																		) : null
																	}
																>
																	{locale['Owner']}
																</Button>
															) : null}
															{!isCreator && !isOwner && (
																<Tooltip
																	aria-label='cross-tooltip'
																	label={
																		!!isDelegated
																			? 'Delegated user'
																			: 'Remove participant'
																	}
																>
																	<IconButton
																		isDisabled={
																			!!isDelegated || !canHaveRemoveAccess
																		}
																		bg='white'
																		borderRadius='md'
																		boxShadow='md'
																		aria-label='More'
																		size='sm'
																		_focus={{
																			bg: 'secondary',
																		}}
																		_hover={{
																			bg: 'secondary',
																		}}
																		icon={<Icon as={MdClose} w={6} h={6} />}
																		onClick={() => {
																			// sectionDetailedTrack({
																			// 	category: GA_CATEGORY_DIALOG_BOX,
																			// 	action: 'Active Participant',
																			// 	label: 'Remove Participant',
																			// })
																			// dataLayerTagManager('button_click', {
																			// 	viewName: 'Add Participant',
																			// 	buttonName: 'Remove Participant',
																			// })
																			removeParticipant(u)
																		}}
																	/>
																</Tooltip>
															)}
														</Flex>
													</ListItem>
													<Divider border='1px' borderColor='secondary' />
												</>
											)
										})}
								</List>
							</TabPanel>
						) : null}
					</TabPanels>
				</Tabs>
			</Box>
		</VStack>
	)
}

export default ActiveParticipantList
